import { DialogController }   from 'aurelia-dialog';
import { bindable, inject }   from 'aurelia-framework';
import { BaseFormViewModel }  from 'base-form-view-model';
import { FormSchema }         from 'modules/management/specifications/listings/add-bulletins-to-listings-modal/form-schema';
import { ListingsRepository } from 'modules/management/specifications/listings/services/repository';
import { AppContainer }       from 'resources/services/app-container';

@inject(AppContainer, ListingsRepository, DialogController, FormSchema)
export class AddBulletinsToListingsModal extends BaseFormViewModel {

    formId = 'management.specifications.listings.creation-form';

    @bindable alert = {};
    model           = {};
    schema          = {};
    create          = true;

    @bindable settings = {
        title:   'form.title.add-bulletins-to-listings',
        size:    'modal-lg',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     * @param formSchema
     */
    constructor(appContainer, repository, dialogController, formSchema) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
        this.formSchema       = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.listings.manage',
            'management.specifications.listings.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        // close bootstrap dropdowns
        $('.dropdown-menu').removeClass('show');
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.addBulletinsToListings(this.model)
            .then((response) => {
                this.handleResponse(response, 'management.specifications.listings.index');
                this.initialModel.assign(this.model);

                if (response.status === true) {
                    this.dialogController.ok();
                }
            })
            .catch((error) => console.error(error));
    }

}
