import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class ListingsRepository extends BaseRepository {
    baseUrl = 'management/specifications/listings';

    /**
     * Get all listings associated to sector
     * @param sectorId
     * @param criteria
     */
    allFromSector(sectorId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/all-from-sector/${sectorId}`, criteria);
    }

    /**
     * Duplicates a record
     *
     * @param duplicatingId
     * @param data
     *
     * @returns {*}
     */
    duplicate(duplicatingId, data) {
        return this.httpClient.post(`${this.baseUrl}/${duplicatingId}/duplicate`, data);
    }

    /**
     * Add bulletins to listings
     *
     * @param data
     * @return {*}
     */
    addBulletinsToListings(data) {
        return this.httpClient.post(`${this.baseUrl}/add-bulletins-to-listings`, data);
    }
}
