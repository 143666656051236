import { inject }                      from 'aurelia-framework';
import { BaseModel }                   from 'modules/core/models/base-model';
import { BulletinRevisionsRepository } from 'modules/management/specifications/bulletins/bulletin-revisions/services/repository';
import { ListingRevisionsRepository }  from 'modules/management/specifications/listings/listing-revisions/services/repository';

@inject(BulletinRevisionsRepository, ListingRevisionsRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param bulletinsRepository
     * @param listingsRepository
     */
    constructor(bulletinsRepository, listingsRepository) {
        this.bulletinsRepository = bulletinsRepository;
        this.listingsRepository  = listingsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {BaseModel}
     */
    model() {
        let model = new BaseModel();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.bulletins_ids = {
            type:         'duallistbox',
            key:          'bulletins_ids',
            label:        'form.field.bulletins',
            required:     true,
            size:         12,
            remoteSource: () => this.bulletinsRepository.all(),
        };

        this.listings_ids = {
            type:         'duallistbox',
            key:          'listings_ids',
            label:        'form.field.listings',
            required:     true,
            size:         12,
            remoteSource: () => this.listingsRepository.all(),
        };

        return [
            [this.bulletins_ids],
            [this.listings_ids],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.cancelButton = {
            type:       'button',
            label:      'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
